import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Image, Button, Container } from "react-bootstrap";
import ErrorImage from "./assets/img/illustrations/image.png";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError() {
    return { hasError: true }; 
  }

  componentDidMount() {
    window.onerror = (error) => {
      this.setState({ hasError: true, error });
      return true;
    };

    window.onunhandledrejection = (event) => {
      this.setState({ hasError: true, error: event.reason });
    };
  }

  componentWillUnmount() {
    window.onerror = null;
    window.onunhandledrejection = null;
  }

  handleGoBackHome = () => {
    window.location.href = "/";
  };

  render() {
    if (this.state.hasError) {
      return (
        <main>
          <section className="vh-100 d-flex justify-content-center align-items-center mt-5">
            <Container>
              <Row className="align-items-center">
                <Col
                  xs={12}
                  lg={5}
                  className="order-2 order-lg-1 text-center text-lg-left"
                >
                  <h1 className="text-primary fontweigh-500 fs-3">
                    Something has gone{" "}
                    <span className="fw-bold">seriously</span> wrong
                  </h1>
                  <p className="lead my-4">
                    It's always time for a coffee break. We should be back by
                    the time you finish your coffee.
                  </p>
                  <Button
                    variant="primary"
                    className="animate-hover green-btn"
                    onClick={this.handleGoBackHome}
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="animate-left-3 me-3 ms-2"
                    />
                    Go back home
                  </Button>
                </Col>
                <Col
                  xs={12}
                  lg={7}
                  className="order-1 order-lg-2 text-center d-flex align-items-center justify-content-center"
                >
                  <Image
                    src={ErrorImage}
                    alt="error"
                    className="img-fluid w-75"
                  />
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
