// redux/planDataSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios";

// Initial plan data function
const getPartnerPlanData = () => ({
  amount: 0,
  expiry_date: "",
  pending_days: 0,
  plan_features: {
    analytics: 0,
    can_add_blogs: 0,
    can_add_brands: 0,
    can_add_deals: 0,
    can_add_dispensaries: 0,
    can_add_products: 0,
    can_add_to_cart: 0,
    contact_info: 0,
    dis_display_on_home: 0,
    dispensaries_limit: 0,
    is_verified: 0,
    link_products: 0,
    order_management: 0,
    ranked_in_top_10: 0,
    response_to_comment: 0,
    review_management: 0,
  },
  plan_id: 0,
  plan_name: "",
  remaining_amount: -1,
});

// Thunk for fetching plan details
export const fetchPlanDetails = createAsyncThunk(
  "planData/fetchPlanDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/user/plan");
      return response.data.data;
    } catch (error) {
      console.error("Failed to fetch plan details:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Slice definition
const initialState = {
  planData: getPartnerPlanData(),
  status: "idle",
  error: null,
};

export const planDataSlice = createSlice({
  name: "plan_data",
  initialState,
  reducers: {
    planDataSet: (state, action) => {
      state.planData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlanDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.planData = action.payload;
      })
      .addCase(fetchPlanDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { planDataSet } = planDataSlice.actions;
export default planDataSlice.reducer;
