import React, { useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import "./LocationGoogleMap.css";

const LocationGoogleMap = ({ data, center, mapHeight }) => {
  const history = useHistory();
  const [markerSizes, setMarkerSizes] = useState({});

  const handleMarkerClick = (item) => {
    history.push({
      pathname: `${Routes.dispensaryDetails.path}/${item.id}/${item.name}/profile`,
    });
  };

  const handleMouseOver = (id) => animateMarkerSize(id, 80);
  const handleMouseOut = (id) => animateMarkerSize(id, 40);

  const animateMarkerSize = (id, targetSize) => {
    const interval = 10; // ms
    const step = 2;
    let currentSize = markerSizes[id] || 40;

    const resize = () => {
      if (
        (targetSize > currentSize && currentSize < targetSize) ||
        (targetSize < currentSize && currentSize > targetSize)
      ) {
        currentSize += targetSize > currentSize ? step : -step;
        setMarkerSizes((prevSizes) => ({
          ...prevSizes,
          [id]: currentSize,
        }));
        setTimeout(resize, interval);
      }
    };

    resize();
  };

  return (
    <GoogleMap
      mapContainerStyle={{
        height: mapHeight,
        borderRadius: "15px",
      }}
      zoom={10}
      center={center}
      options={{
        mapTypeControl: false,
      }}
    >
      {data?.map((item) => (
        <Marker
          key={item.id}
          position={{
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          }}
          icon={{
            url: item.logo,
            scaledSize: new window.google.maps.Size(
              markerSizes[item.id] || 40,
              markerSizes[item.id] || 40
            ),
          }}
          onClick={() => handleMarkerClick(item)}
          onMouseOver={() => handleMouseOver(item.id)}
          onMouseOut={() => handleMouseOut(item.id)}
        />
      ))}
    </GoogleMap>
  );
};

export default LocationGoogleMap;
