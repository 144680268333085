import { useState, useEffect } from "react";
import dummy_cover_image from "../../assets/img/dummy_cover_image.webp";
import tableViewHeroimage from "../../assets/img/tablet_view_hero.webp";
import mobileViewHeroImage from "../../assets/img/mobile_view_hero.webp";
import { Routes } from "../../routes";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
 

export default function LoadingPlaceHolder({ height, image, width}) {
  const [backgroundImage, setBackgroundImage] = useState(dummy_cover_image);

  useEffect(() => {
    const updateImage = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 600 && screenWidth < 900) {
        // `md` breakpoint for tablets
        setBackgroundImage(tableViewHeroimage);
      } else if (screenWidth < 600) {
        setBackgroundImage(mobileViewHeroImage);
      } else {
        setBackgroundImage(dummy_cover_image);
      }
    };

    // Add event listener for resizing
    window.addEventListener("resize", updateImage);

    // Set initial image on mount
    updateImage();

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", updateImage);
    };
  }, []);
  const userLocation = useSelector((state) => state.login.location);
  return (
    <>
      
      {image ? (
        <div
          style={{
            backgroundImage: `linear-gradient(rgba(117, 110, 110, 0.3), rgba(34, 33, 33, 0.3)), url(${backgroundImage})`,
            height: `${height}px`,
            width: `${width}%`,
            margin: "auto",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="text-center">
            <h1 className="text-white fs-3">Browse, Compare and Shop!</h1>
            <h2 className="text-white fs-5 fontweigh-500">
              Discover top-rated dispensaries, explore premium products, and
              find exclusive deals near you.
            </h2>
            <Button
              as={Link}
              className="green-btn mt-4"
              to={{
                pathname: `${
                  Routes.Explore.path
                }/storefronts/state=${userLocation.state?.replaceAll(
                  " ",
                  "-"
                )}`,
              }}
            >
              Explore Now
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{ height: `${height}px` }}
          className="loading-data fs-5 mt-4"
        >
          Loading Data...
        </div>
      )}
    </>
  );
}
