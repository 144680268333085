import axios from "../axios";
import { useDispatch, useSelector } from "react-redux";
import { cartItemsSet } from "../redux/cartSlice";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";
import { loadingSet } from "../redux/loadingSlice";

const useCart = () => {
  const dispatch = useDispatch(); // Getting reference to dispatch function
  const cart = useSelector((state) => state.userCart.cart);
  const userData = useSelector((state) => state.login.userData);
  const history = useHistory();

  const fetchCart = async () => {
    try {
      const res = await axios("/carts");

      dispatch(cartItemsSet(res.data.data));
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const addToCart = async (
    product_id,
    dis_id,
    quantity,
    setShowToast,
    setToastBody,
    setSuccess
  ) => {
    if (!userData.role) {
      history.push({
        pathname: Routes.Signin.path,
        state: { type: "dispensary" },
      });
      return;
    }

    try {
      const res = await axios({
        method: "post",
        url: "/add/cart",
        data: {
          product_id,
          quantity,
          dis_id: dis_id,
        },
      });

      fetchCart();
      setShowToast(true);
      setToastBody(res?.data?.data[0]?.products[0]);
      setSuccess(true);
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response?.data?.message);
      setSuccess(false);
    }
  };
  let updatedCartItems = cart?.cart_items?.map((item) => ({
    product_id: item?.product_id,
    quantity: item?.quantity,
    dis_id: item.dis_id,
  }));
  const updateCart = async (
    product_id,
    dis_id,
    quantity,
    type,
    setShowToast,
    setToastBody,
    setSuccess
  ) => {
    if (type === "increment") {
      updatedCartItems.map((cartItem) =>
        cartItem.product_id === product_id && cartItem.dis_id === dis_id
          ? cartItem.quantity++
          : cartItem
      );
    } else {
      if (quantity > 1)
        updatedCartItems.map((cartItem) =>
          cartItem.product_id === product_id && cartItem.dis_id === dis_id
            ? cartItem.quantity--
            : cartItem
        );
      else {
        updatedCartItems = updatedCartItems.filter((cartItem) => {
          return cartItem.product_id !== product_id;
        });
      }
    }
    const product_ids = updatedCartItems.map((cartItem) => cartItem.product_id);
    const quantities = updatedCartItems.map((cartItem) => cartItem.quantity);
    const dis_ids = updatedCartItems.map((cartItem) => cartItem.dis_id);
    const applied_coupon = cart?.applied_coupon?.coupon_id || "";
    dispatch(loadingSet(true));
    try {
      const res = await axios({
        method: "post",
        url: "/update/cart",
        data: {
          product_id: product_ids,
          quantity: quantities,
          dis_id: dis_ids,
          coupon_id: applied_coupon,
        },
      });
      fetchCart();
      setShowToast(true);
      setToastBody(res.data.message);

      setSuccess(true);
    } catch (err) {
      console.log(err, "erorr");
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  };

  return {
    cart,
    addToCart,
    updateCart,
    fetchCart,
  };
};

export default useCart;
